@font-face {
  font-family: "Radiant";
  src: url("Radiant.woff2") format("woff2"), url("Radiant.woff") format("woff"),
    url("Radiant.ttf") format("truetype");
}

.border-dotted {
  border-style: dotted;
}
.border-2 {
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  border-color: rgba(0, 0, 0, 0.3);
}
.border-for-bottom {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}
.extra-small-font {
  font-size: x-small;
}
.very-small-font {
  font-size: small;
}
.small-font {
  font-size: 0.83em;
}
.dms-navlink {
  color: gray;
}
.dms-navlink-underline {
  color: gray;
  /* font-style: italic; */
  text-decoration: underline;
}
.dms-navlink:hover {
  color: gray;
  font-weight: bold;
  text-decoration: underline;
  /* font-style: italic; */
}
.dms-navlink-underline:hover {
  color: gray;
  font-weight: bold;
  text-decoration: underline;
  /* font-style: italic; */
}
.todo-chart-title {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.image-select-border {
  border-style: solid;
  border-color: orange;
  border-width: 5px;
}
